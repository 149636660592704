<template>
	<div class="main-section" data-aos="fade-up" data-aos-anchor-placement="top-bottom">
		<!--    <div class="section-edu section-item">-->
		<!--      <div class="title-area">-->
		<!--        <small class="bold small-text">소상공인 맞춤교육</small>-->
		<!--        <h4 class="title">슬기로운 디지털 전환, <br/> 소상공인 맞춤교육</h4>-->
		<!--      </div>-->
		<!--      <ul class="edu-video-area">-->
		<!--        <li v-for="(item, idx) in items" class="edu-video-item" :key="idx">-->
		<!--          <div class="edu-thumb">-->
		<!--            <a :href="item.eduUrl" class="image-link" target="_blank"><img :src="item.imgPtUrl" alt=""/></a>-->
		<!--            <div class="edu-playbutton">-->
		<!--              <a :href="item.eduUrl" class="btn-edu-play" target="_blank">-->
		<!--                <i class="icon-play">재생하기</i>-->
		<!--              </a>-->
		<!--            </div>-->
		<!--          </div>-->
		<!--          <div class="edu-content">-->
		<!--            <h5 class="edu-title">-->
		<!--              <a :href="item.eduUrl" class="title-link" data-anim="true" target="_blank">{{ item.eduBannrTit }}</a>-->
		<!--            </h5>-->
		<!--            <p class="edu-description">-->
		<!--              <span class="small-text bold">{{ item.eduBannrCtgr }}</span>-->
		<!--              <i class="icon-edu-playtime">edu_playtime.svg</i>-->
		<!--              <span class="edu-playtime">{{ item.eduPlayHhmm }}</span>-->
		<!--            </p>-->
		<!--          </div>-->
		<!--        </li>-->
		<!--      </ul>-->
		<!--    </div>-->
		<div class="section-community section-item">
			<div class="title-area">
				<!--        <small class="bold small-text">온라인 진출 배움터</small>-->
				<h4 class="title">온라인 진출 배움터</h4>
				<p class="subtext">『한국중소벤처기업유통원』 의 <br />실전 전문가 육성 플랫폼</p>
			</div>
			<router-link to="/education" class="btn btn-white btn-link">
				<span class="text text-primary">함께하기</span>
			</router-link>
			<div class="section-community-image">
				<!-- <img src="../../../assets/mobile/img/main/section_edu_image.jpg" alt="" /> -->
				<img src="../../../assets/mobile/img/main/section_edu_image_v1.png" alt="" />
			</div>
		</div>
	</div>
</template>
<script>
import { ACT_GET_EDU_BANNER_LIST } from '../../../store/_act_consts';
import { getItems, lengthCheck } from '../../../assets/js/utils';
import { aosInit } from '../../../assets/js/aos.config';

export default {
	name: 'MobileSection5_1',
	data: () => ({
		items: [],
	}),
	created() {
		aosInit();
		this.getEduBanners();
	},
	methods: {
		getEduBanners() {
			this.$store
				.dispatch(`main/${ACT_GET_EDU_BANNER_LIST}`, {
					pageSize: 1,
				})
				.then((res) => {
					if (lengthCheck(res)) {
						this.items = getItems(res);
					} else {
						this.items = [];
					}
				})
				.catch((e) => {
					console.error(e);
					this.items = [];
				});
		},
	},
};
</script>
